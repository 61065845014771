const EMPTY_P = '<p data-we-empty-p=""><br></p>'
import moment from "moment";
function valiImgType(str){
    if(!/\.(mp4|MP4)$/.test(str)){
        return true;
    }
    return  false
}
function getBackGroundImg(img,width,height) {
    return `width:${width}px;height:${height}px;background-image: url('${img}');background-repeat: no-repeat;background-size: contain;background-position: center;`
}
var currency = {
    "纽约": "USD",
    "伦敦" :"GBP",
    "香港" : "HKD",
    "巴黎" : "EUR",
    "苏黎世" : "CHF",
    "日内瓦" : "CHF",
    "米兰" : "EUR"
}
export  const getAuctionCard = function (acutionRes,minProgramType){
    if(!acutionRes || !minProgramType){
        throw  new Error("当前参数不能为空")
    }
    let imgStr = acutionRes.img;
    var imgResult = '';
    if(imgStr && imgStr.length > 0){
        let imgArrays = imgStr.split(',')
        if(imgArrays && imgArrays.length > 0){
            imgResult = imgArrays.find(item=>{
                return valiImgType(item)
            })

        }
    }



    const icon_base64img_time = "data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADqADAAQAAAABAAAADgAAAAC98Dn6AAAA50lEQVQoFZWSsQ4BQRCGj9BdpZCQ6L2GhAegUYmG93BvQ0FC4SE0Wp1WQULuBfzfZe7s5paLSb7buX9mNrO7E0W+xfpNxFmkBv5KEAvaQOpVbMRQdAx8NGLkeIZwF2NP/fy0LUZOUUwL7PatqKfYS/Qth9y4pk9i4lRryI4SH2JmQdq+4HN4zhGyucSbaDnBkXxqstvjIrCuWIut8dQ6Ea6RmzZcBUGcRNN0NtiZX1p+tVpKlpC1WpdzEMtQxhdtIX1PrOo53HqeLHuOXKwaAPIo8gbALWa3v0YuL6ZtBoILc4ccjVhhbxTdMvYjawR1AAAAAElFTkSuQmCC"

    /**
     * min-program-card 是否为小程序卡片
     * min-program-type 小程序类型  （MP1：珠宝腕表 MP2：古玩书画 MP3：现代艺术品）
     * card-type 卡片类型  1 拍卖会卡片   2 拍品卡片
     * id 当前卡片资源Id
     *
     * @type {string}
     */

    const cardHtml = `<div style="margin:0 auto;overflow:hidden;width: 343px;height: 339px;background: #FFFFFF;box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.11);border-radius: 8px;" href="?min-program-card='true'&min-program-type='${minProgramType}'&card-type='1'&id='${acutionRes.auctionId}'" class="card-container" contenteditable="false" min-program-card min-program-type="${minProgramType}" card-type="1" id="${acutionRes.auctionId}">
                         <div class="card-img" style="flex-shrink: 0;${getBackGroundImg(imgResult,343,257)}"></div>
                         <div style="padding:0 16px;box-sizing: border-box">
                          <p class="card-title" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;margin:16px 0 0 0;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: bold;color: #000000;line-height: 16px;">${acutionRes.auctionName}</p>
                           <div style="display: flex;flex-direction: row;align-items: flex-start;margin-top: 8px;">
                             <span style="margin:0;flex-shrink: 0;${getBackGroundImg(icon_base64img_time,14,14)}"></span>
                             <div  class="desc-layout" style="margin-top:2px;display:flex;flex-direction:column;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #000000;line-height: 12px;">
                                 <span style="width:calc(100% / 0.8); margin-left:calc(-100% * 0.1);font-size: 12px;line-height:12px !important;transform: scale(0.8);">${moment(Number(acutionRes.startTime)).format("YYYY年MM月DD日 HH:mm")} - ${moment(Number(acutionRes.endTime)).format("YYYY年MM月DD日 HH:mm")} 北京时间</span>
                                 <span style="width:calc(100% / 0.8); margin-left:calc(-100% * 0.1);margin-top: 5px;color: #8F8F8F;font-size: 12px;line-height:12px !important;transform: scale(0.8);">地点：${acutionRes.address}</span>
                             </div>
                          </div>  
                         </div>
                      </div>` + EMPTY_P
    return cardHtml

}
export const getLotCard = function(lotRes,minProgramType){
    if(!lotRes || !minProgramType){
        throw  new Error("当前参数不能为空")
    }
    let imgStr = lotRes.img;
    var imgResult = '';
    if(imgStr && imgStr.length > 0){
        let imgArrays = imgStr.split(',')
        if(imgArrays && imgArrays.length > 0){
            imgResult = imgArrays.find(item=>{
                return valiImgType(item)
            })

        }
    }



    const icon_base64img_time = "data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADqADAAQAAAABAAAADgAAAAC98Dn6AAAA50lEQVQoFZWSsQ4BQRCGj9BdpZCQ6L2GhAegUYmG93BvQ0FC4SE0Wp1WQULuBfzfZe7s5paLSb7buX9mNrO7E0W+xfpNxFmkBv5KEAvaQOpVbMRQdAx8NGLkeIZwF2NP/fy0LUZOUUwL7PatqKfYS/Qth9y4pk9i4lRryI4SH2JmQdq+4HN4zhGyucSbaDnBkXxqstvjIrCuWIut8dQ6Ea6RmzZcBUGcRNN0NtiZX1p+tVpKlpC1WpdzEMtQxhdtIX1PrOo53HqeLHuOXKwaAPIo8gbALWa3v0YuL6ZtBoILc4ccjVhhbxTdMvYjawR1AAAAAElFTkSuQmCC"

    /**
     * min-program-card 是否为小程序卡片
     * min-program-type 小程序类型  （MP1：珠宝腕表 MP2：古玩书画 MP3：现代艺术品）
     * card-type 卡片类型  1 拍卖会卡片   2 拍品卡片
     * id 当前卡片资源Id
     *
     * @type {string}
     */
    // height: 348px;
    const cardHtml = `<div style="margin:0 auto;overflow:hidden;width: 343px;background: #FFFFFF;box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.11);border-radius: 8px;" href="?min-program-card='true'&min-program-type='${minProgramType}'&card-type='2'&id='${lotRes.lotsId}&currency=${currency[lotRes.address]}'" class="card-container" contenteditable="false" min-program-card min-program-type="${minProgramType}" card-type="2" id="${lotRes.lotsId}">
                         <div class="card-img" style="flex-shrink: 0;${getBackGroundImg(imgResult,343,220)}"></div>
                         <div style="padding:0 16px;box-sizing: border-box">`
                       + (minProgramType =='MP1'?
                            `<p class="card-title" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;margin:16px 0 0 0;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: bold;color: #000000;line-height: 16px;">${lotRes.brandName}</p>`
                          :`<p class="card-title" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;margin:3px 0 0 0;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: bold;color: #000000;line-height: 16px;${!lotRes.author?'display: none;':''}">${lotRes.author}</p>`)

                         + `<p class="card-title" style="text-overflow: ellipsis;overflow: hidden;margin:16px 0 0 0;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: bold;color: #000000;line-height: 18px;max-height: 54px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;">${lotRes.lotsName?lotRes.lotsName:''}</p>
                          <div style="margin-bottom:12px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #8F8F8F;line-height: 14px;transform: scale(0.8);margin-left: -11%;margin-top: 5px;">估值 ${lotRes.priceLow || lotRes.priceHigh ? (lotRes.priceLow + ' - ' + lotRes.priceHigh + ' ' + currency[lotRes.address]) :'估价待询' }</div>
                         </div>
                      </div>` + EMPTY_P
    return cardHtml
}
