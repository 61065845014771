
import Editor from "wangeditor/dist/wangEditor";
const {$,Panel} = Editor
class PanelCustom  extends  Panel{
    constructor(menu,auctionCallbackFn,lotCallbackFn) {
        super(menu)
        this.auctionCallbackFn = auctionCallbackFn;
        this.lotCallbackFn = lotCallbackFn;
    }




    /**
     * 创建并展示 panel
     */
     create() {
        const menu = this.menu
        if (Panel.createdMenus.has(menu)) {
            // 创建过了
            return
        }
        // panel 的容器
        const $container = this.$container
        const width =  200 // 默认 300px
        const rect = menu.editor.$toolbarElem.getBoundingClientRect()
        const menuRect = menu.$elem.getBoundingClientRect()
        const top = rect.height + rect.top - menuRect.top
        let left = (rect.width - width) / 2 + rect.left - menuRect.left
        const offset = 200 // icon与panel菜单距离偏移量暂定 300
        if (Math.abs(left) > offset) {
            // panel菜单离工具栏icon过远时，让panel菜单出现在icon正下方，处理边界逻辑
            if (menuRect.left < document.documentElement.clientWidth / 2) {
                // icon在左侧
                left = -menuRect.width / 2
            } else {
                // icon在右侧
                left = -width + menuRect.width / 2
            }
        }

        $container
            .css('width', width + 'px')
            .css('margin-top', `${top}px`)
            .css('margin-left', `${left}px`)
            .css('z-index', menu.editor.zIndex.get('panel'))

        // 添加关闭按钮
        const $closeBtn = $('<i class="w-e-icon-close w-e-panel-close"></i>')
        $container.append($closeBtn)
        $closeBtn.on('click', () => {
            this.remove()
        })

        // 准备 tabs 容器
        const $selectContainer = $('<div class="select-container"></div>')
        $container.append($selectContainer)
        // const datas = ["1","1","1","1","1","1","1","1"];
        // datas.forEach(item=>{
        //     const  $item = $(`<div class="item" style="background-color: red;width: 100%;height: 40px">23432</div>`)
        //     $selectContainer.append($item)
        //     $item.on('click',()=>{
        //         alert("当前选中")
        //     })
        // })
        const title = $(`<p>请选择插入内容</p>`)
        $selectContainer.append(title)
        const $auctionButton = $(`<button>拍卖会</button>`)
        $auctionButton.on('click', (e)=> {

            if(this.auctionCallbackFn){
                this.auctionCallbackFn();
            }
            this.remove()
        })
        $selectContainer.append($auctionButton)
        const $lotContentButton = $(`<button>拍品</button>`)
        $lotContentButton.on('click', (e) => {
            if(this.lotCallbackFn){
                this.lotCallbackFn();
            }
            this.remove()
        })
        $selectContainer.append($lotContentButton)
        // const $done;
        // $done.on('click',()=>{
        //     const editor =  this.menu.editor
        //     const {cmd,selection} = editor;
        //
        //     const EMPTY_P = '<p data-we-empty-p=""><br></p>'
        //     const emptyTags = ['<p><br></p>', '<br>', '<p data-we-empty-p=""></p>', EMPTY_P]
        //     const $textElem = editor.$textElem
        //     const html = editor.$textElem.html()
        //     const text = editor.$textElem.text()
        //     const txtHtml = html.trim()
        //     // // 编辑器中的字符是""或空白，说明内容为空
        //     if (/^\s*$/.test(text) && (!txtHtml || emptyTags.includes(txtHtml))) {
        //         editor.$textElem.children().forEach(item=>{
        //             item.remove();
        //         })
        //     }
        //
        //
        //     const $currentElem = selection.getSelectionContainerElem();
        //     function isInContainerCardRange($elem) {
        //         if($elem.getNode().getAttribute("container_card") == null){
        //             if(editor.$textElem.equal($elem)){
        //                   return false;
        //             }
        //             return isInContainerCardRange($elem.parent())
        //         }else{
        //             return  true
        //         }
        //     }
        //     function isContainerCardElem($currentChildElem) {
        //         return $currentChildElem.getNode().getAttribute("container_card") != null
        //     }
        //     function getTopContainerCardRange($elem){
        //         if($elem.getNode().getAttribute("container_card") == null){
        //             if(editor.$textElem.equal($elem)){
        //                 return editor.$textElem;
        //             }
        //             return getTopContainerCardRange($elem.parent())
        //         }else{
        //             return  $elem
        //         }
        //     }
        //     const cardlayout =
        //         '<div contenteditable="false"  container_card class="item-card">' +
        //         '<div>'+
        //         '<span style="color: red">112</span>' +
        //         '<span style="color: red">112</span>' +
        //         '</div>'+
        //         '<div style="color:#000;">3423</div>' +
        //         '</div>'+  EMPTY_P
        //     // const container_and_cardlayout = `<div  container_card>${cardlayout}<div/>`
        //     // let $topContainerCardRange = getTopContainerCardRange($currentElem);
        //     // if(!isInContainerCardRange($currentElem)){
        //     //     $currentElem.append($(container_and_cardlayout))
        //     //     var newRangeElem = $currentElem.children().first()
        //     //     selection.setRangeToElem($currentElem.getNode())
        //     //     selection.moveCursor($currentElem.getNode())
        //     // }else{
        //     //     // editor.cmd.do('insertHTML',cardlayout)
        //     //     $topContainerCardRange.append($(cardlayout))
        //     //     selection.setRangeToElem($topContainerCardRange.getNode())
        //     //     selection.moveCursor($topContainerCardRange.getNode())
        //     //
        //     // }
        //     editor.cmd.do('insertHTML',cardlayout)
        //     /**
        //      * 中间空行添加
        //      */
        //     // editor.$textElem.append($(cardlayout))
        //     // selection.setRangeToElem(editor.$textElem.getNode())
        //     // selection.moveCursor(editor.$textElem.getNode())
        //
        // })

        // 绑定关闭事件
        $container.on('click', (e) => {
            // 点击时阻止冒泡
            e.stopPropagation()
        })

        // 添加到 DOM
        menu.$elem.append($container)

        // 隐藏其他 panel
        Panel.hideCurAllPanels()

        // 记录该 menu 已经创建了 panel
        menu.setPanel(this)
        Panel.createdMenus.add(menu)
    }

}

export default PanelCustom
