<template>
  <div class="loadingModal">
    <a-modal
      :visible="loadingModal"
      centered
      :footer="null"
      :closable="false"
      :maskClosable="false"
    >
      <div class="loading_warp">
        <a-progress
          :percent="loadProcess"
          status="active"
          strokeColor="#00253e"
        />
        <div class="loading_text">
          文件上传中...
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "loadingModal",
  props: {
    loadProcess: {
      type: Number,
      default: 0
    },
    loadingModal: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.loading_warp {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .loading_text {
    text-align: center;
  }
}
</style>
