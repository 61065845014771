<template>
    <div>
        <div id="wangedittor"></div>
        <!--    拍卖会弹窗-->
        <auctions
                :isVisible="selectAuctions"
                :auctionsData="auctionsData"
                :loading="auctionsLoading"
                @OnAuctionCancel="OnAuctionCancel"
                @insertClick="insertClick"
                @auctionSearch="auctionSearch"
                @showSizeChangeAuction="showSizeChangeAuction"
        ></auctions>
        <!--    拍品弹窗-->
        <auctions-item
                :isVisible="selectAauctionsItemModal"
                :auctionsItemData="auctionsItemData"
                :loading="auctionsItemLoading"
                @auctionItemInsert="auctionItemInsert"
                @showSizeChange="showSizeChange"
                @onAuctionItemCancel="onAuctionItemCancel"
                @auctionItemSearch="auctionItemSearch"
        ></auctions-item>
        <div class="mask-loading" v-if="videoloadingVisible">
            <a-spin />
        </div>
    </div>
</template>

<script>
    const EMPTY_P = '<p data-we-empty-p=""><br></p>'
    import API from "@/request";
    import Card from '../menu/Card'
    import Link from '../menu/link'
    import Editor from 'wangeditor/dist/wangEditor';
    const {Panel} = Editor
    import upload from "@/utils/upload";
    import auctions from "@/views/content/components/auctions";
    import AuctionsItem from "@/views/content/components/auctionsItem";
    import {getAuctionCard,getLotCard} from "../menu/utils"
    export default {
        name: "ritch-text",
        data(){
            return{
                videoloadingVisible:false,
                editor : null,
                editorContent:'',
                selectAuctions: false, // 选择拍卖会对话框显示隐藏
                auctionsItemLoading: false, // 控制拍品列表加载效果
                auctionsLoading: false, // 控制拍卖会列表加载效果
                selectAauctionsItemModal: false, // 控制拍品列表显示隐藏
                auctionsItemData: { // 拍品列表
                    size: 10,
                    total: 0,
                    records:[],
                    current : 1
                }, 
                auctionsData:{ // 拍卖会列表
                    size: 10,
                    total: 0,
                    records:[],
                    current : 1
                }
            }
        },
        model:{
          prop:'contentHtml',
          event:'contentHtmlChange'
        },
        props:{
            // contentHtml:String,
            contentHtml:{
                type:String,
                default:''
            },
            minProgramType:{
                type:String,
                required:true
            },
        },
        components:{
            AuctionsItem,
            auctions,
        },

        methods:{
            clearHtml(){
                this.editor.txt.html('')
                // 隐藏其他 panel
                 Panel.hideCurAllPanels()
            },
            auctionSearch(val) {
                // 拍卖会搜索
                this.getAuctionOrlots(val);
            },
            // 获取全部拍卖会列表
            async getAuctionOrlots(keyWord,current) {
                this.auctionsLoading = true;
                let res = await API.auctionOrlots({
                    keyWord,
                    type: 1,
                    current: current?current:1,
                    size: 10,
                    groupId:0,
                });
                if (res.data.code === "0000") {
                    console.log(res.data.data.records);
                    this.auctionsData = res.data.data;
                    this.auctionsLoading = false;
                }
            },
            auctionItemSearch(val) {
                // 拍品搜索
                this.searchKey = val;
                this.getAuctionItemsList(val);
            },
            showSizeChangeAuction(current,size){
                this.getAuctionOrlots(this.searchKey,current)
            },
            showSizeChange(current,size){
                this.getAuctionItemsList(this.searchKey,current)
            },
            //获取全部拍品列表
            async getAuctionItemsList(keyWord,current) {
                this.auctionsItemLoading = true;
                try {
                    let res = await API.auctionOrlots({
                        keyWord,
                        type: 2,
                        current: current?current:1,
                        size: 10,
                        groupId:0,
                        // groupId:this.minProgramType =='MP1'?1:this.minProgramType =='MP2'?2:this.minProgramType =='MP3'?3:'',
                    });
                    if (res.data.code === "0000") {
                        console.log(res.data.data);
                        this.auctionsItemData = res.data.data;
                        this.auctionsItemLoading = false;
                    }
                }catch (e) {
                    console.log(e)
                }

            },
            // 关闭选择拍品列表
            onAuctionItemCancel() {
                this.selectAauctionsItemModal = false;
                this.auctionsItemLoading = false;
                this.auctionsItemData = {
                    size: 10,
                    total: 0,
                    records:[],
                    current : 1
                };
            },
            auctionItemInsert(val) {
                // 接收拍品插入内容的Id
                let res = this.auctionsItemData.records.find(item => {
                    return item.lotsId === val;
                });
                const html = getLotCard(res,this.minProgramType);
                this.editor.cmd.do("insertHTML",html)
                this.selectAauctionsItemModal = false;
                this.auctionsItemLoading = false;
                this.auctionsItemData = {
                    size: 10,
                    total: 0,
                    records:[],
                    current : 1
                };
            },
            selectAauctions() {
                // 选择拍卖会
                this.selectAuctions = true;
                this.auctionsLoading = true;
                this.getAuctionOrlots();
            },
            OnAuctionCancel() {
                // 关闭拍卖会列表
                this.selectAuctions = false;
                this.auctionsLoading = false;
                this.auctionsData = {
                    size: 10,
                    total: 0,
                    records:[],
                    current : 1
                };
            },

            insertClick(val) {
                // 接收拍卖会插入内容的下标
                let res = this.auctionsData.records.find(item => {
                    return item.auctionId === val;
                });
                const html = getAuctionCard(res,this.minProgramType);
                this.editor.cmd.do("insertHTML",html)
                this.selectAuctions = false;
                this.auctionsLoading = false;
                this.auctionsData = {
                    size: 10,
                    total: 0,
                    records:[],
                    current : 1
                };
            },
            // 选择拍品
            selectAauctionsItem() {
                this.selectAauctionsItemModal = true;
                this.auctionsItemLoading = true;
                this.getAuctionItemsList();
            },
        },
        // watch:{
        //     contentHtml(){
        //         this.editor.txt.html(this.contentHtml )
        //     }
        // },
        mounted(){
            Editor.registerMenu("card",Card)
            Editor.registerMenu("linkmine",Link)
            this.editor = new Editor('#wangedittor');
            this.editor.txt.eventHooks.changeEvents.push(()=>{
                this.$emit('contentHtmlChange',this.editor.txt.html())
            })
            this.editor.config = Object.assign(this.editor.config ,{
                height : 800,
                styleWithCSS:true,
                menus:[
                    // 'head',
                    'bold',
                    'fontSize',
                    // 'customFontSize',
                    'fontName',
                    'italic',
                    'underline',
                    'strikeThrough',
                    'indent',
                    'lineHeight',
                    'foreColor',
                    'backColor',
                    // 'link',
                    // 'list',
                    // 'todo',
                    'justify',
                    'quote',
                    // 'emoticon',
                    'image',
                    'video',
                    // 'table',
                    // 'code',
                    // 'splitLine',
                    'undo',
                    'redo',
                ],
                uploadImgMaxSize: 2 * 1024 * 1024, // 2M
                uploadImgAccept : ['jpg', 'jpeg', 'png', 'gif'],
                uploadImgMaxLength:5,
                showLinkImg:false,
                customUploadImg:function (resultFiles,insertImgFn) {
                    if(resultFiles.length > 0){

                        resultFiles.forEach(file => {
                            var pathName =
                                "ritchImg/" +
                                new Date().valueOf() +
                                // parseInt(Math.random() * 10000) +
                                file.name;
                            upload.uploadFile(pathName,file).then(res=>{
                                insertImgFn(res)
                                console.log("-------------")
                            }).catch(err=>{
                                this.$message.error("图片" + file.name + '上传失败')
                            })
                        })




                    }


                },
                uploadVideoAccept:["mp4"],
                showLinkVideo:false,
                customUploadVideo :function (resultFiles,insertVideoFn) {
                    if(resultFiles.length > 0){
                        var promiseTask = [];
                        this.videoloadingVisible = true;
                        resultFiles.forEach(file => {
                            var pathName =
                                "ritchImg/" +
                                new Date().toLocaleDateString() +
                                // parseInt(Math.random() * 10000) +
                                file.name;
                            promiseTask.push(new Promise((resolve, reject) => {
                                upload.uploadFile(pathName,file).then(res=>{
                                    insertVideoFn(res)
                                    resolve()
                                }).catch(err=>{
                                    this.$message.error("视频" + file.name + '上传失败')
                                    reject(err)
                                })
                            }))
                        })
                        Promise.all(promiseTask).then(res =>{
                            this.videoloadingVisible = false;
                        }).catch(e =>{
                            this.videoloadingVisible = false;
                        })




                    }


                }.bind(this),
                colors:['#000000',
                        '#eeece0',
                        '#1c487f',
                        '#4d80bf',
                        '#c24f4a',
                        '#8baa4a',
                        '#7b5ba1',
                        '#46acc8',
                        '#f9963b',
                        '#00253E',
                        '#C29B40'

                ],
                fontNames:[
                    '苹方-常规',
                    'Arial',
                    'Times New Roman'
                ]
            })
            this.editor.create();
            this.editor.txt.eventHooks.menuClickEvents.push((e)=>{


                    const emptyTags = ['<p><br></p>', '<br>', '<p data-we-empty-p=""></p>', EMPTY_P]
                    const html = this.editor.$textElem.html()
                    const text = this.editor.$textElem.text()
                    const txtHtml = html.trim()
                    // // 编辑器中的字符是""或空白，说明内容为空
                    if (/^\s*$/.test(text) && (!txtHtml || emptyTags.includes(txtHtml)) && !this.isFocused) {
                        this.editor.initSelection(true)
                        this.isFocused = true;
                    }
            })
            Card.prototype.auctionCallbackFn = ()=>{
                this.selectAauctions()
            }
            Card.prototype.lotCallbackFn = ()=>{
                this.selectAauctionsItem()
            }
            this.editor.txt.html(  this.contentHtml  + EMPTY_P)
        },
        beforeDestroy(){
            this.editor.destroy();
            this.editor = null;
        },
    }
</script>

<style scoped>
     .mask-loading{
         position: fixed;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         z-index: 100000;
         background-color: rgba(0,0,0,0.1);
         display: flex;
         align-items: center;justify-content: center;
     }
</style>
