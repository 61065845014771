import Editor from "wangeditor/dist/wangEditor";
const {$,PanelMenu} = Editor
import PanelCustom from "./Panel";
import './card.less'
function isFirefox(){
    var _ua = navigator.userAgent
    return /Firefox\/\d+/.test(_ua) && !/Seamonkey\/\d+/.test(_ua) ? true : false
}

function getParentTop($elem) {
    if($elem.elems[0].getAttribute("container_card")!=null){
        return $elem;
    }else{
        return  getParentTop($elem.parent())
    }
}
function isContainer_card($elem) {
    if(!$elem.elems || $elem.elems.length < 1){
        return  false
    }

    return $elem.getNode().getAttribute("container_card") != null
}
function parentIsContainerCard($elem,editor) {
    if(!$elem.elems || $elem.elems.length < 1){
        return  false
    }
    if($elem.equal(editor.$textElem)){
        return false
    }else{
        if(!isContainer_card($elem.parent())){
            return parentIsContainerCard($elem.parent(),editor)
        }
        return true;
    }
}
function bindEvent(editor){
    if (isFirefox()) return
    const { txt, selection,cmd } = editor
    const { deleteDownEvents,keydownEvents,changeEvents } = txt.eventHooks
    keydownEvents.push(function (e) {


    })
    deleteDownEvents.push(function (e) {
        var containerElem = selection.getSelectionContainerElem();
        var position = selection.getCursorPos();
        if(containerElem.text().length > 0 && position == 0 ){
           var preElem = containerElem.prev();
            if($(preElem).hasClass('card-container')){
                $(preElem).remove();
            }
        }
    })
    // deleteDownEvents.push(function (e) {
    //     var $startElem = selection.getSelectionStartElem();
    //     var $endElem = selection.getSelectionEndElem();
    //     const $containerElem = selection.getSelectionContainerElem();
    //     var cPos = selection.getCursorPos();
    //     // console.log($startElem,$endElem,$containerElem,cPos)
    //     //单点选中
    //     const EMPTY_P = '<p data-we-empty-p=""><br></p>'
    //     if($startElem.equal($endElem)){
    //         var $deleteElem;
    //         if(isContainer_card($containerElem)){
    //             $deleteElem = $containerElem.children().get(cPos - 1);
    //
    //         }else if(parentIsContainerCard($containerElem,editor)){
    //              $deleteElem = getParentTop($containerElem)
    //         }else{
    //            var $cPosElem  = $containerElem.children().get(cPos - 1);
    //             if(isContainer_card($cPosElem)){
    //                 $deleteElem = $cPosElem;
    //             }else if(parentIsContainerCard($cPosElem,editor)){
    //                 $deleteElem = getParentTop($cPosElem)
    //             }
    //         }
    //         if($deleteElem){
    //             let $preElem = $deleteElem.prev();
    //             $preElem.append($(EMPTY_P))
    //             $deleteElem.remove();
    //         }
    //
    //     }else{
    //      //区域选中
    //      //    console.log($startElem,$endElem);
    //         var $deleStartElem = $startElem;
    //         var $deleEndElem = $endElem;
    //         if( isContainer_card($startElem)){
    //             $deleStartElem = $startElem
    //         }
    //         if( isContainer_card($endElem)){
    //             $deleEndElem = $endElem
    //         }
    //         if(parentIsContainerCard($startElem,editor)){123
    //             $deleStartElem = getParentTop($startElem)
    //         }
    //         if(parentIsContainerCard($endElem,editor)){
    //             $deleEndElem = getParentTop($endElem)
    //         }
    //         // isContainer_card($deleStartElem)&&$deleStartElem.remove()&&console.log("11")
    //         // isContainer_card($deleEndElem)&&$deleEndElem.remove()&&console.log("22")
    //         selection.createRangeByElems($deleStartElem.getNode(),$deleEndElem.getNode());
    //         cmd.do("insertHtml","")
    //     }
    // })
}
class Card extends PanelMenu {
    $container = null;
    constructor(editor) {
        const $elem = $(
            `<div class="w-e-menu" data-title="卡片">
                <i class="w-e-icon-custom-image-svg"></i>
            </div>`
        )

        super($elem, editor)
        this.$container = $('<div class="w-e-panel-container"></div>')
        // 绑定事件 tootip
        bindEvent(editor);

    }

    /**
     * 菜单点击事件
     */
     clickHandler(e) {
        // 弹出 panel
        this.createPanel(this.editor)
    }

    auctionCallbackFn () {
    }
     lotCallbackFn () {
    }

    /**
     * 创建 panel
     * @param link 链接
     */

     createPanel(editor) {
        const panelcustom = new PanelCustom(this,this.auctionCallbackFn,this.lotCallbackFn);
        panelcustom.create()
    }
    removePannel(editor){
        const $container = this.$container
        $container.remove();
    }

    /**
     * 尝试修改菜单 active 状态
     */
     tryChangeActive() {}
}

export default Card
