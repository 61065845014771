<template>
  <div>
    <a-modal
      v-model="Show"
      @cancel="onCancel"
      title="推文&视频"
      :footer="null"
      :zIndex="9999"
    >
      <div class="main_view mobile_preview" v-if="peData">
        <div class="title_view">
          <label>{{ peData ? peData.title : "" }}</label>
        </div>
        <div class="time_view">
          <template v-if="peData">
            <div>
              <p v-if="peData.author">作者：{{peData.author}}</p>
              <p
              >
              发布时间：{{
                peData.activeTime
                ?formatDate(Number(peData.activeTime))
                :formatDate(new Date())
                }}</p
              >
            </div>


          </template>
        </div>
        <template v-if="peData.content">
          <div v-html="peData.content"></div>
        </template>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "mobilePreview",
  props: {
    //显示隐藏
    isShow: {
      type: Boolean,
      value: false
    },
    // 数据
    previewData: {
      type: Object,
      value: {}
    }
  },
  watch: {
    isShow: {
      handler(newVal) {
        this.Show = newVal;
      }
    },
    previewData: {
      handler(newvarl) {
        this.peData = newvarl;
      }
    }
  },
  data() {
    return {
      // 1:文字 2:图片 3:视频 4:拍卖会 5:
      Show: this.isShow,
      peData: this.previewData
    };
  },
  methods: {
    moment,
    onCancel() {
      this.peData = {}
      this.$emit("onCancel");
    },
    create() {},

    /**
     * 格式化 img src
     * @param img
     * @returns {*}
     */
    formtSrc(img) {
      var imgArr = img.split(",");
      return imgArr[0];
    },
    formatDate(date) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY年M月D日 HH:mm';
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    date = new Date(date.replace(/-/g, '/'));
  }
  if (typeof date === 'number') {
    date = new Date(date);
  }
  var hours = date.getHours();
  var o = {
    'A': hours > 18 ? '晚间' : hours > 12 ? '下午' : '上午',
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': hours % 12 === 0 ? 12 : hours % 12,
    'H+': hours,
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S': date.getMilliseconds() };

  var week = {
    '0': "\u65E5",
    '1': "\u4E00",
    '2': "\u4E8C",
    '3': "\u4E09",
    '4': "\u56DB",
    '5': "\u4E94",
    '6': "\u516D" };

  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1 ? RegExp.$1.length > 2 ? "\u661F\u671F" : "\u5468" : '') +
    week[date.getDay() + '']);
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return fmt;
}
  }
};
</script>

<style scoped>
  /deep/.ant-modal-body p{
    margin-top: 1em;
    margin-bottom: 1em;

  }

/deep/ .ant-modal-close {
  top: 25px;
  right: 21px;
}
/deep/ .ant-modal-close i {
  font-size: 20px;
}

/deep/ .ant-modal-header {
  display: none;
}
/deep/ .ant-modal-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_view {
  height: 650px;
  overflow-y: scroll;
  padding: 10px;
  margin: 40px 0;
}
/deep/ .ant-modal-content {
  overflow: auto;
  background-image: url("../assets/image/phonebg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  width: 430px;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  margin: 0 auto;
}
/deep/ .ant-modal-body {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  box-shadow: none;
}
.header_view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
}
.title_view {
  font-weight: bold;
  color: #000000;
  font-size: 21px;
  /*background-color: white;*/
}
.time_view {
  margin: 10px 0;
  color: #8f8f8f;
  font-size: 12px;
}
.img_view {
  margin: 10px 0;
}
.img_view img {
  width: 100%;
}
.auction {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  margin: 10px 0;
  background: #ffffff;
}
.auction_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 31px;
}
.auction_content .item {
  flex: 1;
  padding: 10px;
  height: 147px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  margin: 10px 5px;
  display: flex;
  align-items: center;
}
.auction_content .item img {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.foot_time {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  color: #000000;
  font-size: 8px;
  flex-direction: column;
}
.time_ico {
  margin-right: 5px;
  font-size: 15px;
  margin-left: 5px;
}
/* 滚动条样式 */
.main_view::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.auction_title {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}
.addres {
  color: #8f8f8f;
  font-size: 10px;
  margin-left: 25px;
  padding-bottom: 10px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: rgba(153, 153, 153, 0.4);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(153, 153, 153, 0.4);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
.lotsImg_view {
  width: 100%;
  height: auto;
}
.lots_view {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  margin: 10px 0;
  background: #ffffff;
  padding: 10px;
}
.lots_name {
  color: #000000;
  font-size: 14px;
  margin: 5px 0;
  font-weight: bold;
}
.lots_about {
  color: #000000;
  font-size: 14px;
}
.lots_gz {
  color: #8f8f8f;
  font-size: 12px;
  margin-top: 10px;
}
.lotsImg_view img {
  width: 100%;
  height: auto;
}
.video_view {
  position: relative;
  margin-bottom: 10px;
}
.video_maskView {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.video {
  width: 100%;
  height: auto;
  background-color: white;
}
</style>
